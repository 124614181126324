import { Box, Typography, useTheme, Stack, IconButton } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import AlertDialog from "../../components/ChooseDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";

const Reportes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [reportes, setReportes] = useState([]);
  const { isMobile } = useAuth();
  const [confirmDial, setConfirmDial] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };

  const fecthReportes = () => {
    fetch(`${API_URL}auth/reports/reports`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setReportes(data.data.reverse());
        } else {
          setReportes([]);
        }
      })
      .catch((err) => {
        setReportes([]);
        console.log(err);
      });
  };

  const handleCloseDial = () => {
    setConfirmDial(false);
  };

  const handleDelete = () => {
    setConfirmDial(false);
    fetch(`${API_URL}auth/reports/report/${idToDelete}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast("¡Reporte eliminado exitosamente!", "success");
          fecthReportes();
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        showToast("Error en el servidor, intentelo más tarde", "error");
        console.log(err);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "name",
      headerName: "Nombre",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row.reported_user_name} {row.reported_user_lastname}
          </Typography>
        );
      },
    },
    {
      field: "reason",
      headerName: "Razon ",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comments",
      headerName: "Comentarios",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={() => {
                setIdToDelete(row.id);
                setConfirmDial(true);
              }}
              color="secondary"
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    fecthReportes();
  }, []);

  return (
    <Box m="20px">
      <Header title="Reportes" subtitle="Revisa los reportes de los usuarios" />

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <AlertDialog
        open={confirmDial}
        handleClose={handleCloseDial}
        title={"¿Está seguro de borrar este reporte?"}
        content={"No lo podra recuperar"}
        handleConfirm={handleDelete}
      />
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },

          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={reportes}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Reportes;
