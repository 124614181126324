import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import Form from "./scenes/form";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Files from "./scenes/files";
import PrivateRoute from "./utils/PrivateRoute";
import Login from "./scenes/Login/Login";
import { useAuth } from "./context/AuthContext";
import Cookies from "js-cookie";
import Agents from "./scenes/agents/Agents";
import Orders from "./scenes/orders/Orders";
import Videos from "./scenes/videos/videos";
import Verificaciones from "./scenes/verificaciones/verificaciones";
import Afiliados from "./scenes/afiliados/Afiliados";
import Consultas from "./scenes/consultas/Consultas";
import CreateAgent from "./scenes/agents/Create_Agent";
import AprobarAgentes from "./scenes/agents/Aprobar_Agentes";
import Coupons from "./scenes/coupons/Coupons";
import VerificacionesAfiliados from "./scenes/verificaciones_afiliados/Verificaciones_Afiliados";
import Retiros from "./scenes/retiros/retiros";
import Roles from "./scenes/admin/Roles";
import UsuariosPanel from "./scenes/admin/Usuarios";
import CreatePlayer from "./scenes/team/Create_Player";
import DeleteAccount from "./scenes/delete/DeleteAccount";
import Reportes from "./scenes/reports/Reportes";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { isAuthenticated, login } = useAuth();

  useEffect(() => {
    // Verificar si la cookie de autenticación está presente al cargar la aplicación
    const isLoggedIn = Cookies.get("isLoggedIn");

    if (isLoggedIn === "true") {
      const isAdmin = JSON.parse(Cookies.get("isAdmin"));
      const userId = JSON.parse(Cookies.get("userId"));
      login(isAdmin, userId);
    }
  }, []);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isAuthenticated && <Sidebar isSidebar={isSidebar} />}
          <main
            className="content"
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isAuthenticated && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/new-agent" element={<CreateAgent />} />
              <Route path="/eliminar-cuenta" element={<DeleteAccount />} />
              <Route path="/ref/:referralCode" element={<CreatePlayer />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/players" element={<Team />} />
                <Route path="/agents" element={<Agents />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/videos" element={<Videos />} />
                <Route path="/verifications" element={<Verificaciones />} />
                <Route path="/members" element={<Afiliados />} />
                <Route path="/form" element={<Form />} />
                <Route path="/consults" element={<Consultas />} />
                <Route path="/reports" element={<Reportes />} />
                <Route path="/approve_agents" element={<AprobarAgentes />} />
                <Route path="/coupons" element={<Coupons />} />
                <Route path="/roles" element={<Roles />} />
                <Route path="/panel-users" element={<UsuariosPanel />} />
                <Route
                  path="/verifications-referrals"
                  element={<VerificacionesAfiliados />}
                />
                <Route path="/withdraws" element={<Retiros />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
